<template>
  <div class="home-page">
    <div class="d-flex flex-column align-items-center">
      <IngredientSearch />
      <router-link to="/results" class="find-button">
        <span class="find-button__text1">Find a cocktail</span>
        <span class="find-button__text2">Find a cocktail</span>
      </router-link>
    </div>
    <StockBox />
  </div>
</template>

<script>
import IngredientSearch from "@/components/IngredientSearch.vue";
import StockBox from "@/components/StockBox.vue";
import applyMaskMixin from "@/mixins/utils/applyMask.js";

export default {
  name: "bar-builder-page",
  components: {
    IngredientSearch,
    StockBox,
  },
  mixins: [applyMaskMixin],
};
</script>
