<template>
  <div class="ingredient-search">
    <div class="ingredient-search__search-bar">
      <input
        class="ingredient-search__search-bar-input"
        type="text"
        placeholder="Search ingredient"
        maxlength="30"
        v-model="name"
      />
      <vSelect
        class="category-selector"
        placeholder="All"
        :options="ingredientcategorylist"
        :reduce="(name) => name.key"
        label="name"
        v-model="selected"
      ></vSelect>
    </div>
    <ul
      v-if="ingredientsloaded"
      class="ingredient-search__results-list scroll-top"
      @scroll="applyMask('ingredient-search__results-list')"
    >
      <li
        v-for="ingredient in searchingredients"
        v-show="matchInput(ingredient)"
        @click="ingredientClick(ingredient)"
        :key="ingredient"
      >
        <div
          class="ingredient-button"
          :class="{ 'ingredient-button__selected': isInBar(ingredient) }"
        >
          <div
            class="ingredient-button__icon"
            v-html="ingredient.icon_raw_svg"
          ></div>
          <div class="ingredient-button__name">{{ ingredient.name }}</div>
        </div>
      </li>
    </ul>
    <div
      v-else
      class="ingredient-search__loader-container d-flex justify-content-center"
    >
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
import removeAccentMixin from "@/mixins/utils/removeAccent.js";
import applyMaskMixin from "@/mixins/utils/applyMask.js";

export default {
  name: "IngredientSearch",
  mixins: [removeAccentMixin, applyMaskMixin],
  data() {
    return { name: "", selected: null, options: [] };
  },
  computed: {
    searchingredients() {
      if (this.selected === null) {
        return this.mainStore.ingredients;
      } else {
        return this.mainStore.ingredients.filter(
          (ingredient) => this.selected === ingredient.category["key"]
        );
      }
    },
    ingredientsloaded() {
      return this.mainStore.ingredientsLoaded;
    },
    ingredientcategorylist() {
      return this.mainStore.ingredientCategoryList;
    },
  },
  updated() {
    this.applyMask("ingredient-search__results-list");
  },
  methods: {
    isInBar(ingredient) {
      if (this.mainStore.stockIngredients.includes(ingredient)) {
        return true;
      } else {
        return false;
      }
    },
    ingredientClick(ingredient) {
      if (this.mainStore.stockIngredients.includes(ingredient)) {
        this.mainStore.removeFromStock(ingredient);
      } else {
        this.mainStore.addToStock(ingredient);
        var nav = document
          .getElementsByClassName("main-header__stock-nav")
          .item(0);
        //shake stock nav working on iOS
        nav.classList.remove("main-header__stock-nav-shake");
        void nav.offsetWidth;
        nav.classList.add("main-header__stock-nav-shake");
        //shake stock nav working on Android
        nav.animate(
          [
            { transform: "translateX(10px)" },
            { transform: "translateX(-10px)" },
            { transform: "translateX(10px)" },
            { transform: "translateX(-10px)" },
          ],
          { duration: 300 }
        );
      }
    },
    matchInput(ingredient) {
      var research = ingredient.name
        .toLowerCase()
        .concat(",", ingredient.complementarySearch.toLowerCase());
      if (
        this.removeAccent(research).includes(
          this.removeAccent(this.name.toLowerCase())
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
